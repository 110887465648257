<template>
  <section class="pd-20">
    <aPageHeader class="mb-0 pd-0">
      <template #title>
        <h1 class="iblock relative top5">
          <img src="@/assets/images/agreement.png" alt="ico" />
          Relatório de origem do cliente
        </h1>
      </template>
      <template #extra></template>
    </aPageHeader>

    <aRow class="haya-panels" :gutter="20">
      <aCol :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contract.meta ? contract.meta.total : 0 }}
          </div>
          <div class="txt">Contratos</div>
          <div class="footer">
            Total Geral: R$
            {{ contract.meta ? contract.meta.total_sold : "0" }}
          </div>
        </div>
      </aCol>
    </aRow>

    <aCollapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <aCollapsePanel key="1">
        <template #header>
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <aRow class="fiter-collumns" :gutter="20">
          <aCol :span="3">
            <div class="travel-input">
              <label class="filled">ID do contrato</label>
              <a-input
                placeholder="ID + Enter"
                v-model="contract.filters.id"
                @pressEnter="getContracts()"
              />
            </div>
          </aCol>

          <aCol :span="4">
            <div class="travel-input">
              <label class="filled">Contratante </label>
              <a-auto-complete
                :data-source="
                  customers.list.map(({ first_name, last_name, id }) => ({
                    value: id,
                    text: `${id} - ${first_name} ${last_name}`,
                  }))
                "
                v-model="customers.filters.searchTerm"
                style="width: 100%; height: 32px"
                placeholder="Buscar contratantes..."
                @change="
                  customers.filters.searchTerm ? getCustomers() : getContracts()
                "
                @select="customerSelected"
              />
            </div>
          </aCol>

          <aCol :span="4">
            <div class="travel-input">
              <label class="filled">Empresas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                v-model="contract.filters.companies.selected"
                show-search
                allow-clear
                style="width: 100%"
                @change="onSelectCompany"
              >
                <a-select-option
                  v-for="(item, index) of companies.list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.trading_name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="7">
            <div class="travel-input">
              <label class="filled">Filiais</label>

              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="contract.filters.companyBranches.selected"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="(item, index) of companyBranches.list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.id }} - {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label class="filled">Período</label>

              <a-range-picker
                v-model="contract.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label class="filled">Origem do cliente</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                v-model="contract.filters.customerOrigin"
                show-search
                allow-clear
                style="width: 100%"
                @change="onSelectCompany"
              >
                <a-select-option
                  v-for="(item, index) of customerOriginList"
                  :key="index"
                  :value="item.name"
                  :txt="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>
        </aRow>
        <a-divider class="mt-0 mb-15" />
        <a-button
          class="mb-0"
          type="primary"
          :loading="contract.loading"
          @click="getContracts()"
          ><a-icon type="search" /> Filtrar contratos</a-button
        >
      </aCollapsePanel>
    </aCollapse>

    <aTable
      class="travel-table"
      :columns="reportColumns"
      :data-source="contract.list"
      :loading="contract.loading"
      :pagination="{
        pageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: pageSizeOptions,
      }"
      :scroll="{ x: 1300 }"
    >
      <div slot="customer" slot-scope="record">
        <div class="dotted-phrase upper">
          {{ theContractor(record) }}
        </div>
      </div>

      <div slot="customer_origin" slot-scope="record">
        {{ record.meta.customer_origin }}
      </div>

      <div slot="company" slot-scope="record">
        {{ record.company_details.trading_name }}
      </div>

      <div slot="company_branch" slot-scope="record">
        {{ record.company_branch.name }}
      </div>

      <div slot="value" slot-scope="record">
        {{ record.value | formatPricePtBr }}
      </div>

      <div slot="release_date" slot-scope="record">
        <a-tooltip :title="record.created | formatDateTime">
          {{ record.release_date | formatDate }}
        </a-tooltip>
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Ver contrato">
          <a
            class="relative"
            v-on:click.ctrl="viewContractDetails(record.id)"
            v-on:click.exact="viewContractDetails(record.id)"
            style="top: -4px"
          >
            <img
              src="@/assets/images/dashboard/contracts/details-ico.png"
              width="19"
              alt="details"
            />
          </a>
        </a-tooltip>
      </div>
    </aTable>

    <aModal
      class="contract-details pd-0"
      v-model="openContractDetailsModal"
      :title="false"
      :footer="false"
      width="884px"
      @cancel="openContractDetailsModal = false"
    >
      <ContractResume
        v-if="openContractDetailsModal"
        :contractId="contractId"
      />
    </aModal>
  </section>
</template>

<script>
import { format } from "date-fns";
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import customerMixins from "@/components/customers/mixins/customerMixins";
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins";
import companyMixins from "@/mixins/companies/companyMixins";
import ContractResume from "@/components/contracts/modals/ContractModal.vue";

export default {
  name: "ContractCustomerOriginReport",
  components: { ContractResume },
  mixins: [
    contractMixins,
    customerMixins,
    companyBranchMixins,
    companyMixins,
    formatThings,
  ],
  data() {
    return {
      contractId: "",
      pageSizeOptions: ["10", "20", "30", "50", "100", "500", "9999"],
      openContractDetailsModal: false,
      customerOriginList: [],
      reportColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Contratante",
          scopedSlots: { customRender: "customer" },
          width: 300,
        },

        {
          title: "Valor",
          scopedSlots: { customRender: "value" },
          width: 110,
        },

        {
          title: "Origem",
          scopedSlots: { customRender: "customer_origin" },
          width: 200,
        },
        {
          title: "Empresa",
          scopedSlots: { customRender: "company" },
          key: "company",
          width: 120,
        },
        {
          title: "Filial",
          scopedSlots: { customRender: "company_branch" },
          key: "company_branch",
          width: 140,
        },
        {
          title: "Criação/Lançamento",
          scopedSlots: { customRender: "release_date" },
          width: 150,
          sorter: true,
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 100,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    document.title = `Relatório de origem do cliente - HAYA`;

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      firstDay = new Date(y, m, 1),
      lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.contract.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    this.contract.filters.status.selected = "concluded";
    this.contract.pagination.perPage = 30;
    this.contract.filters.orderBy = "created";
    this.contract.filters.order = "desc";

    this.companyBranches.pagination.perPage = 50;

    this.contractUrlDefaults();
    this.getContracts();
    this.getCompanyBranches();
    this.getCompanies();

    this.$http
      .get(`/category-entry/list?page=1&category_id=24&per_page=300`)
      .then(({ data }) => {
        this.customerOriginList = data.data;
      });
  },
  methods: {
    viewContractDetails(id) {
      this.contractId = id;
      this.openContractDetailsModal = true;
    },
    customerSelected(customer) {
      this.customers.filters.searchTerm = customer;
      this.contract.filters.customer = customer;
    },
    onSelectCompany(val) {
      this.companyBranches.filters.companyId = val;
      this.companyBranches.list = [];
      this.getCompanyBranches();
    },
    theContractor(contract) {
      let theContractor = "";

      if (contract.customer.person_type === "Pessoa Física")
        theContractor = `${contract.customer.id} - ${contract.customer.first_name} ${contract.customer.last_name}`;

      if (contract.customer.person_type === "Pessoa Jurídica")
        theContractor = `${contract.customer.id} - ${contract.customer.trading_name}`;

      return theContractor;
    },
  },
};
</script>

<style lang="sass">
.contract-details
  .ant-modal-close
    top: -48px
    padding: 0 !important
    height: 20px
    width: 20px
    color: #FFF !important
    right: 16px
  .ant-modal-body
    padding: 0
</style>
